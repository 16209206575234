import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useReducer } from 'react';
import useApiViaje from '../../../api/operaciones/useApiViajes';
import FieldDropDown from '../../../shared/controls/fieldDropDown';
import FieldInputNumber from '../../../shared/controls/fieldInputNumber';
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import formReducer from '../../../shared/forms/formReducer';
import FormHandler from "../../../shared/forms/formHandler";
import { agregarAnticipo } from "./viajeValidacionEsquema";
import FieldCheckBox from '../../../shared/controls/fieldCheckBox';
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import React , { useState  } from 'react'
import alerts from "../../../shared/alerts";
import FieldRadioButtonList from "../../../shared/controls/fieldRadioButtonList";
const AgregarAnticipo = ({ viajeId, onSave, visible, hide }) => {
    const api = useApiViaje();
    const defaultEntity = () => ({
        entity: {
            operador: null,
            concepto: null,
            monto: null,
            comentario: null,
            urgente: false,
            extProv: 3,
        }
    });
    const [{ entity, errors }, dispatch] = useReducer(formReducer, {}, defaultEntity);
    
    const [cuentas, setCuentas] = useState(null);
    const externals = [  
        { id: 3, nombre: "Cuenta Ximple" },
        { id: 1, nombre: "Provisional" },
        { id: 2, nombre: "Externo" },
    ];

    const formHandler = new FormHandler({
        validationSchema: agregarAnticipo,
        api,
        dispatch
    });

    const actualizar = async (value,propertyName) => {  
        if (propertyName ==="operador" ){
            const result = await api.cuentasBancarias(value.id);
            setCuentas(result);    
        }
        if (propertyName === "extProv") {
            dispatch({ type: 'update', value, name: propertyName });
        } else {
            dispatch({ type: 'update', value, name: propertyName });
        }
        dispatch({ type: 'update', value: value, name: propertyName });
    };  
    const guardar = async () => {
        const seguir = await formHandler.validate(entity);
        console.log(seguir)
        if(entity.urgente){
            if ((await alerts.preguntarSiNo("¿Está seguro de realizar la transferencia?")) === true) {
                if (seguir.result) {
                    const nuevo = await api.agregarAnticipo(viajeId, entity);
                    onSave(nuevo);
                    dispatch({
                        type: "update",
                        value: { ...defaultEntity }
                    });
                }
            }            
        }
        else{
            if (seguir.result) {
                const nuevo = await api.agregarAnticipo(viajeId, entity);
                onSave(nuevo);
                dispatch({
                    type: "update",
                    value: { ...defaultEntity }
                });
            } 
        }             
    };


    const close = () => {
        dispatch({
            type: "update",
            value: { ...defaultEntity() } 
        });
        hide();
    }
    const renderFooterModal = () => {
        return (
            <div>
                  <Button label={entity.urgente ? "Transferir" : "Guardar"} onClick={guardar} autoFocus />
            </div>
        );
    }

    return (
        <Dialog header="Agregar gasto" visible={visible} style={{ width: '60vw' }} footer={renderFooterModal()} onHide={close}>       
               <div className="form-row">   
                <FieldCheckBox 
                        name="urgente" 
                        label="Urgente" 
                        value={entity.urgente} 
                        className="py-1" 
                        flexflow="row-reverse" 
                        colMd={12}
                        onChange={actualizar} 
                    />
                </div>  
                {entity.urgente && (
                      <div className="d-flex justify-content-center">
                        <div className={`form-group col-md-6 col-sm-12}`}>                
                            <FieldRadioButtonList
                                name="extProv"
                                colMd={12}
                                options={externals}
                                value={entity.extProv}
                                onChange={actualizar}
                            />    
                        </div> 
                    </div> 
                )}   
                <div className="form-row">
                {(entity.extProv ===1 ||  entity.extProv ===3)&&(
                    <>
                        <FieldDropDown 
                            name="operador" 
                            source="operadoresSegmento" 
                            value={entity.operador} 
                            label="Operador"
                            params={{ id: viajeId }} 
                            required 
                            colMd={12} 
                            error={errors?.operador}
                            onChange={actualizar} 
                        />
                           </>
                    )}
                    <>
                     {entity.urgente && entity.extProv ===3 &&(
                        <FieldDropDown
                            name="cuentas"
                            value={entity.cuentas}
                            label="Cuentas Operador"
                            options={cuentas}
                            colMd={12}
                            onChange={actualizar}
                        />
                    )}
                    </>
                
                {entity.extProv===2 && (
                    <>
                        <FieldTextBox 
                            name="nombreexterno" 
                            value={entity.nombreexterno} 
                            label="Nombre"
                            required 
                            colMd={12}
                            onChange={actualizar} 
                        />
                    </>
                  )}
                {entity.urgente && (entity.extProv ===2 || entity.extProv===1) && (
                    <>
                        <FieldDropDown 
                            name="Banco" 
                            source="bancos" 
                            value={entity.bancos} 
                            label="Banco" 
                            filter={true}
                            colMd={6} 
                            onChange={actualizar} 
                        />
                        <FieldTextBox 
                            name="clabeAnticipo" 
                            value={entity.clabeAnticipo} 
                            label="CLABE" 
                            colMd={6}
                            onChange={actualizar} 
                        />
                    </>
                )}
                
                
                <FieldDropDown name="tipoGasto" source="cajachica/tipoGastos" value={entity?.tipoGasto} label="Tipo de Gasto"  filter={true}
                    required colMd={6} error={errors?.tipoGasto}
                    onChange={actualizar} />
                <FieldDropDown name="conceptoCajaChica" source="cajachica/conceptos" value={entity?.conceptoCajaChica} label="Concepto"  filter={true}
                    required colMd={6} error={errors?.conceptoCajaChica} params={{ tipoGastoId: entity?.tipoGasto?.id }}
                    onChange={actualizar} />
                <FieldInputNumber name="monto" value={entity.monto} fractionDigits={2} label="Monto"
                    required colMd={6} error={errors?.monto}
                    onChange={actualizar} />
                <FieldTextArea name="comentario"  label="Comentario" value={entity.comentario} colMd={12} rows={3}
                                         error={errors?.comentario} onChange={actualizar} />
            </div>

        </Dialog>
    )
}

export default AgregarAnticipo;
