import useServiceFactory from "../serviceFactory";

const apiUrl = `/operaciones/viajes/programacion`;
const apiUrlSeguimiento = `/operaciones/viajes/seguimiento`;
const apiUrlMultiempresa = '/operaciones/viajes/facturacionMultiEmpresa';
const apiUrlEdi = "/operaciones/viajes/edi";
const useApiViaje = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params, showLoading: true }),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, { showLoading: true }),
        obtenerRutas: async (params) => service.get(`${apiUrl}/obtenerRutas`, { params }),
        obtenerConvenios: async (ids, tipoViajeId, showLoading = true) =>
            service.get(`${apiUrl}/obtenerConveniosPorIds/${ids}/${tipoViajeId}`, { showLoading }),
        obtenerUnidades: async (id, params) => service.get(`${apiUrl}/${id}/obtenerUnidades`, {
            params,
            showLoading: true
        }),
        obtenerOperadores: async (params) => service.get(`${apiUrl}/obtenerOperadores`, {
            params, showLoading: true
        }),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        guardarComentarios: async (id, params) => service.put(`${apiUrl}/${id}/comentarios`, params, { showSuccess: true }),
        guardarDescripcion: async (id, params) => service.put(`${apiUrl}/${id}/descripcion`, params, { showSuccess: true }),
        timbrar: async (id, params) => service.post(`${apiUrl}/${id}/timbrar`, params, { showSuccess: true }),
        cartaporteXml: async (id, facturacionMultiempresa, empresaId) => service.post(`${apiUrl}/${id}/cartaporteXml/${facturacionMultiempresa}/${empresaId}`, { showSuccess: true }),
        sendEmail: async (id, facturacionMultiempresa) => service.post(`${apiUrl}/${id}/sendEmail/${facturacionMultiempresa}`, { showSuccess: true }),
        cancelarTimbrado: async (id, params) => service.put(`${apiUrl}/${id}/cancelarTimbrado`, params, { showSuccess: true }),
        cartaportePdf: async (id, cartaPorte, facturacionMultiempresa, empresaId) => service.get(`${apiUrl}/${id}/cartaportePdf/${facturacionMultiempresa}/${empresaId}`,
            {
                showLoading: true,
                download: true,
                fileName: `cartaPorteTmibrada_${cartaPorte}.pdf`,
            }),
        actualizar: async (id, params) => service.put(`${apiUrl}/${id}`, params),
        asignarRecursos: async (id, params) => service.put(`${apiUrl}/${id}/asignarRecursos`, params),
        limpiarRecursos: async (id) => service.put(`${apiUrl}/${id}/limpiarRecursos`),
        cancelar: async (id, params) => service.put(`${apiUrl}/${id}/cancelar`, params),
        eliminar: async (ids) => service.delete(`${apiUrl}/${ids}`),
        clonar: async (ids, params) => service.post(`${apiUrl}/${ids}/clonar`, params),        
        solicitamosDryRun: async (id) => service.put(`${apiUrl}/${id}/solicitarDryRun`),
        obtenerUnidadViajeAnterior: async (id, unidadId) => service.get(`${apiUrl}/${id}/obtenerUnidadViajeAnterior/${unidadId}`,
            { showLoading: true }),
        descargar: async (id, cartaPorte) => service.get(`${apiUrl}/${id}/pdfCartaPorte`,
            {
                showLoading: true,
                download: true,
                fileName: `cartaPorte_${cartaPorte}.pdf`
            }),
        excel: async (params, esAcceso) => service.get(`${apiUrl}/excel${esAcceso ? 'Accesos' : 'Salidas'}`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: esAcceso ? "ControlPatios_Accesos.xlsx" : "ControlPatios_Salidas.xlsx"
            }),
        reporte: async (params) => service.get(`${apiUrl}/reporte`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `VentasUnidades.xlsx`
            }),
        reporteProgramaPenske: async (params) => service.get(`${apiUrl}/reporteProgramaPenske`,
        {
                params,
                showLoading: true,
                download: true,
                fileName: `ProgramaPenske.xlsx`
        }),   
        cartaPorteLeida: async (id) => service.post(`${apiUrl}/${id}/pdfCartaPorte`, {}, { showLoading: false, showSuccess: false }),
        validarArticulos: async (params) => service.post(`${apiUrl}/viajeArticuloValidar`, params),
        validarCajaExterna: async (numeroEconomico, placas, showLoading = false) => service.get(`${apiUrl}/validaCajaExterna/${numeroEconomico}/${placas}`, { showLoading }),
        eliminarArticulos: async (id, params) => service.post(`${apiUrl}/${id}/viajeEliminarArticulo`, params, { showSuccess: true }),
        addAnticipo: async (id, params) => service.post(`${apiUrl}/${id}/viajeAddAnticipo`, params),
        eliminarAnticipos: async (id, params) => service.post(`${apiUrl}/${id}/viajeEliminarAnticipo`, params, { showSuccess: true }),
        actualizarDistanciaGeocercas: async (id) => service.post(`${apiUrl}/actualizarDistanciaGeocercas/${id}`, {}, { showSuccess: true }),
        agregarAnticipo: async (id, params) => service.post(`${apiUrl}/agregarAnticipo/${id}`, params, { showSuccess: true }),
        agregarCompensacion: async (id, params) => service.post(`${apiUrl}/agregarCompensacion/${id}`, params, { showSuccess: true }),
        agregarDescuento: async (id, params) => service.post(`${apiUrl}/agregarDescuento/${id}`, params, { showSuccess: true }),
        actualizarComision: async (id, params) => service.post(`${apiUrl}/actualizarComision/${id}`, params, { showSuccess: false }),
        getRuta: async (id) => service.get(`${apiUrl}/${id}/ruta`, { showLoading: true }),
        facturas: async (id) => service.get(`${apiUrl}/${id}/facturas`, { showSuccess: false }),
        regresaraProgramado: async (id) => service.put(`${apiUrlSeguimiento}/${id}/regresaraProgramado`),
        regresaraaPorProgramar: async (id) => service.put(`${apiUrlSeguimiento}/${id}/regresaraaPorProgramar`),
        facturarMultiple: async (id, params) => service.put(`${apiUrl}/${id}/facturarMultiple`, params),
        filtrarMultiempresa: async (params) => service.get(`${apiUrlMultiempresa}`, { params, showLoading: true }),
        obtenerMultiempresa: async (id) => service.get(`${apiUrlMultiempresa}/${id}`, { showLoading: true }),
        timbrarMultiempresa: async (id, params) => service.post(`${apiUrlMultiempresa}/${id}/timbrar`, params, { showSuccess: true }),
        reporteMultiempresa: async (params) => service.get(`${apiUrlMultiempresa}/reporte`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `VentasUnidades.xlsx`
            }),
        validarCartaPorte: async (id, cartaPorte) => service.put(`${apiUrl}/cartaporte/${id}/validar/${cartaPorte}`),
        viajeFraccionValidar: async (params) => service.get(`${apiUrl}/viajeFraccionValidar`, { params}),
        incidenciaTimbrado: async (id, params) => service.put(`${apiUrl}/IncidenciaTimbrado/${id}`, params),
        enviarEdi990D: async (id, clave) => service.post(`${apiUrlEdi}/${id}/enviarEdi990D/${clave}`, {}, { showSuccess: false }),
        obtenerOperacionPenske: async (id) => service.get(`${apiUrl}/obtenerOperacionPenske/${id}`, { showLoading: true }),
        filtrarOperacionPenske: async (params) => service.get(`${apiUrl}/operacionPenske`, { params, showLoading: true }),    
        cuentasBancarias: async (id) => service.get(`${apiUrl}/cuentasBancarias/${id}`, { showLoading: true }),
        pdfComprobante: async (id) => service.get(`${apiUrl}/${id}/comprobanteStp`,
            {
                showLoading: true,
                download: true,
                fileName: "comprobanteStp.pdf"
            }),
    }
};

export default useApiViaje;
