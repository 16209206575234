/* eslint-disable react-hooks/exhaustive-deps */
import {
    Circle,
    GoogleMap,
    InfoBox,
    LoadScript,
    Marker,
    Polygon,
    Polyline,
    TrafficLayer
} from "@react-google-maps/api";
import {defaultMapOptions, geocercaOptions, tipoGeocerca, tipoStop} from "../SeguimentoConstantes";
import React, {useEffect, useRef, useState} from "react";
import useRuta from "./useRuta";
import RutaUnidadEnMapa from "./RutaUnidadEnMapa";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {useSeguimientoMonitoreo} from "../SeguimientoMonitoreoProvider";
import {useControlMap} from "../ControlMapProvider";
import {Checkbox} from "primereact/checkbox";
import {utcToString} from "../../../../shared/format";
import MapsDirectionsRenderer from "./MapsDirectionsRenderer";
import {OverlayPanel} from "primereact/overlaypanel";
import {containerStyle, getIcon, getMapSliderRanges, libraries} from "../SeguimientoMonitoreutility";
import {WorksheetIcon} from "../../../../icons/SeguimientoYMonitoreo/SeguimientoIcons";
import useApiSeguimientoMonitoreo from "../../../../api/operaciones/useApiSeguimientoMonitoreo";
import renderMarkersWithLabels from "./renderMarkersWithLabels";

let geocercaTimeout = null;


const Mapa = ({posiciones, unidadesOcultas, firstLoad, ruta}) => {

    const [googleMap, setGoogleMap] = React.useState(null)
    const [visibleMarcadores, setVisibleMarcadores] = useState(false)
    const {puntosRuta, marcadores, stops, horas, onChangeHoras, mostrandoRuta} = useRuta(ruta)
    const {centrarMapaCount, onMostrarGeocercas, mostrarRuta, mostrarRuteo, mapa, unidadViajeMap} = useSeguimientoMonitoreo() ?? {};
    const api = useApiSeguimientoMonitoreo()


    const {
        geocercasVisibles,
        mostrarOcultarGeocercas,
        lugaresRuteo,
        ocultarMenu,
        setGeocercasVisibles,
        notificacionSeleccionada
    } = useControlMap() ?? {geocercasVisibles: [], ocultarMenu: true}


    const [geocercasEnMapa, setGeocercasEnMapa] = useState(new Set())
    const [zoom, setZoom] = useState(0)

    const [trafico, setTrafico] = useState(false)
    const [etiquetasUnidades, setEtiquetasUnidades] = useState(false)
    const [etiquetas, setEtiquetas] = useState(false)
    const [stopSeleccionado, setStopSeleccionado] = useState()
    const [marks, setMarks] = useState([])
    const [fechasRuta, setFechasRuta] = useState()

    const op = useRef(null);

    useEffect(()=>{
        const ranges = getMapSliderRanges();
        setMarks(ranges.range)
        setFechasRuta(ranges.fechas)
    },[ruta.unidadId])

    const centrarMapa = (posiciones, zoom) => {
        if (googleMap == null || window.google == null)
            return;

        let bounds = new window.google.maps.LatLngBounds();

        for (let posicion of posiciones) {
            let myLatLng = new window.google.maps.LatLng(posicion.lat, posicion.lng);
            bounds.extend(myLatLng);
        }

        if (posiciones.length !== 0)
            googleMap.fitBounds(bounds);

        if (zoom == null) {

            googleMap.getZoom()

            if (googleMap.getZoom() > 15)
                googleMap.setZoom(12)
        } else {
            googleMap.setZoom(zoom)
        }

    }

    useEffect(()=>{
        if (googleMap != null) {
            googleMap.setCenter(mapa.center)
            googleMap.setZoom(mapa.zoom)
        }
    }, [mapa])

    useEffect(() => {
        centrarMapa(posiciones)
    }, [unidadesOcultas, firstLoad, googleMap, centrarMapaCount]);

    useEffect(() => {
        if (notificacionSeleccionada != null && window.google != null) {
            googleMap.setCenter(notificacionSeleccionada)
            googleMap.setZoom(17)
        }
    }, [notificacionSeleccionada]);


    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        setGoogleMap(map)
        map.addListener('zoom_changed', () => {
            const zoom = map.getZoom();
            setVisibleMarcadores(zoom >= 12)
            setZoom(zoom)
        });

        map.addListener('bounds_changed', () => {
            if (setGeocercasVisibles != null)
                setGeocercasVisibles(e => containsPolygon(map, e))
        });

    }, [])


    function containsPolygon(map, geocercas) {
        clearTimeout(geocercaTimeout)
        geocercaTimeout = setTimeout(() => {
            const result = new Set()
            for (let geocerca of geocercas) {
                if (map.getBounds().contains(geocerca)) {
                    result.add(geocerca.id);
                }
            }
            setGeocercasEnMapa(result)
        }, 500);

        return geocercas
    }

    const onUnmount = React.useCallback(function callback() {
        setGoogleMap(null)
    }, [])

    useEffect(() => {
        if (ruta?.puntos?.length > 0 && googleMap != null && !ruta?.rangoExtendido) {
            googleMap.setCenter(ruta.puntos[ruta.puntos.length - 1])
            googleMap.setZoom(13)
        }


    }, [ruta]);
    const getGeocercaOptions = (item) => {
        return {
            ...geocercaOptions,
            fillColor: `#${item.color}`,
            strokeColor: `#${item.color}`,
        }
    }

    useEffect(() => {
        if (geocercasVisibles.length === 0 || geocercasVisibles.length > 100)
            return

        centrarMapa(geocercasVisibles.map(e => e.puntos[0]), geocercasVisibles.length > 1 ? null : 16)
    }, [geocercasVisibles]);

    const onMostrarOcultarGeocercas = (event) => {
        event.stopPropagation()
        mostrarOcultarGeocercas(event.checked)

    }

    const getIconStop = (stop) => {
        if (stop.tipo === tipoStop.stop) {
            return "../../img/seguimientoYMonitoreo/stops.svg";
        }
        return "../../img/seguimientoYMonitoreo/parkings.svg";
    }

    const onMarkerStopMouseOver = (e, item) => {
        setStopSeleccionado(item)
        op.current.toggle(e.domEvent)
    }

    const onMarkerStopMouseOut = () => {
        op.current.hide()
    }

    const handleChangeHours = async (value) => {
        if (value[1] > 23 && !ruta.rangoExtendido) {
            await mostrarRuta(ruta.unidadId, ruta.viajeId, true, ruta.puntos[ruta.puntos.length - 1].fecha)
        }
        onChangeHoras(value)
    }

    const exportRuta = async ()=>{
        await api.exelRuta({unidadId:ruta.unidadId, fechaDe:fechasRuta[horas[1]], fechaHasta:fechasRuta[horas[0]]})
    }



    return (<div className={'seg-contenedor-mapa'}>
            {(mostrandoRuta()) &&
                <div className={'mapa-horas-slider'}>
                    <div className={'horas'}>Horas
                        <WorksheetIcon className={'export'} onClick={exportRuta}/>
                    </div>
                    <Slider style={{height: '700px'}} vertical range value={horas} onChange={handleChangeHours}
                            marks={marks} min={0} max={25} defaultValue={0}/>
                </div>
            }
            {ocultarMenu !== true &&
                <div className={'mapa-menu'}>
                    <div className="mapa-menu-boton checkbox" onClick={onMostrarGeocercas}>
                        <Checkbox id={"geocercasCheckBox"} inputId="binary" checked={geocercasVisibles.length > 0}
                                  onChange={onMostrarOcultarGeocercas}/>
                        <label className={'checkbox-label'}>Geocercas</label>
                    </div>
                    <div className="mapa-menu-boton checkbox">
                        <Checkbox inputId="trafico" checked={trafico} onChange={e => setTrafico(e.checked)}/>
                        <label className={'checkbox-label'} htmlFor="trafico">Tráfico</label>
                    </div>
                    <div className="mapa-menu-boton checkbox" onClick={() => mostrarRuteo()}>
                        Ruteo
                    </div>
                    <div className="mapa-menu-boton checkbox">
                        <Checkbox inputId="trafico" checked={etiquetasUnidades} onChange={e => setEtiquetasUnidades(e.checked)}/>
                        <label className={'checkbox-label'} htmlFor="trafico">Etiquetas Unidades</label>
                    </div>
                    {ruta?.marcadores?.length > 0 &&
                        <div className="mapa-menu-boton checkbox">
                            <Checkbox inputId="etiquetas" checked={etiquetas} onChange={e => setEtiquetas(e.checked)}/>
                            <label className={'checkbox-label'} htmlFor="etiquetas">Etiquetas Ruta</label>
                        </div>
                    }
                </div>
            }
            <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY} libraries={libraries}>
                <GoogleMap
                    options={defaultMapOptions}
                    mapContainerStyle={containerStyle}
                    center={mapa.center}
                    zoom={mapa.zoom}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    {geocercasVisibles?.map((item) => {
                        return (
                            <React.Fragment key={item.id}>
                                {item.tipo === tipoGeocerca.linea && (
                                    <Polyline
                                        path={item.puntos}
                                        options={getGeocercaOptions(item)}
                                    >
                                    </Polyline>
                                )}
                                {item.tipo === tipoGeocerca.poligono && (
                                    <Polygon
                                        paths={item.puntos}
                                        options={getGeocercaOptions(item)}
                                    />
                                )}
                                {item.tipo === tipoGeocerca.circulo && (
                                    <Circle
                                        center={item.puntos[0]}
                                        radius={item.puntos[0].r}
                                        options={getGeocercaOptions(item)}
                                    />
                                )}
                                {zoom > 10 && geocercasEnMapa.has(item.id) && geocercasEnMapa.size < 50 &&
                                    <InfoBox
                                        position={{lat: item?.lat, lng: item?.lng}}
                                        options={{
                                            closeBoxURL: ``,
                                            enableEventPropagation: true,
                                            disableAutoPan: true,
                                            pixelOffset: new window.google.maps.Size(-30, -10)
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: "1.3em",
                                                color: `black`,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {item.nombre}

                                        </div>
                                    </InfoBox>
                                }

                            </React.Fragment>
                        );
                    })}

                    {
                        posiciones.map((item, index) => (
                            <React.Fragment key={index}>
                                <Marker
                                    key={index}
                                    icon={getIcon(item, unidadViajeMap.get(item.unidadId))}
                                    title={`${item.esCaja ? 'Caja' : 'Unidad'}: ${item.numeroEconomico}`}
                                    position={item}
                                    zIndex={10}
                                />
                            </React.Fragment>
                        ))
                    }

                    {etiquetasUnidades  && renderMarkersWithLabels(posiciones, unidadViajeMap)}

                    {notificacionSeleccionada &&
                        <Marker
                            position={notificacionSeleccionada}
                        />
                    }
                    {puntosRuta && puntosRuta.length > 0 &&
                        <RutaUnidadEnMapa puntosRuta={puntosRuta} marcadores={marcadores}
                                          visbleMarcadores={visibleMarcadores}/>
                    }

                    {etiquetas && marcadores && marcadores.map((item) => (
                        <InfoBox
                            position={{lat: item?.lat, lng: item?.lng}}
                            options={{closeBoxURL: ``, enableEventPropagation: true, disableAutoPan: true}}
                            key={item.fecha}
                            zIndex={0}
                        >
                            <div
                                style={{
                                    backgroundColor: 'blue',
                                    opacity: 0.75,
                                    padding: 2,
                                    width: "78px",
                                    fontSize: "9px",
                                    color: "white",
                                }}
                            >
                                {utcToString(item.fecha, "DD-MMM hh:mm A")}
                            </div>
                        </InfoBox>
                    ))}

                    {etiquetas && stops.map((item, index) => (
                        <Marker
                            icon={getIconStop(item)}
                            key={index}
                            position={item}
                            onMouseOver={(e) => onMarkerStopMouseOver(e, item)}
                            onMouseOut={onMarkerStopMouseOut}
                            zIndex={0}
                        />
                    ))}

                    {trafico &&
                        <TrafficLayer/>
                    }
                    <MapsDirectionsRenderer places={lugaresRuteo}/>
                </GoogleMap>
            </LoadScript>

            <OverlayPanel ref={op} id="overlay_panel" style={{width: '300px'}} className="overlaypanel-demo">
                <div className={'seg-stop'}>
                    <div>
                        <div className={'title'}>
                            {stopSeleccionado?.tipo === tipoStop.stop ? 'Stop' : 'Parking'}
                        </div>
                        <div>
                            {utcToString(stopSeleccionado?.fecha, "DD-MMM hh:mm A")}
                        </div>
                    </div>
                    <div>
                        <span className={'bold label'}>Ubicacion:</span>
                        <span>{stopSeleccionado?.ubicacion}</span>
                    </div>
                    <div>
                        <span className={'bold label'}>Duración:</span>
                        <span>{stopSeleccionado?.duracion}</span>
                    </div>
                </div>
            </OverlayPanel>


        </div>
    )
}
export default Mapa;