import React from "react";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import produce from "immer";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import FieldInputNumeroTarjeta from "../../../shared/controls/customInputs/fieldInputNumeroTarjeta";
import FieldInputNumeroClabe from "../../../shared/controls/customInputs/fieldInputNumeroClabe";
import FieldInputNumeroPhone from "../../../shared/controls/customInputs/fieldInputPhone";
import {SelectButton} from "primereact/selectbutton";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import constantes from "../../constantes";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import useSeguridad from "../../../Seguridad/useSeguridad";

const Nomina = ({nomina,dataTipoEmpleado,name, onChange, errors, deshabilitar,modoCajaahorro}) => {

    const options = ['$', '%'];
    function actualizar(value, propertyName) {
        const nuevo = produce(nomina ?? {}, draft => {
            draft[propertyName] = value
        });
        onChange(nuevo, name);
    }
    const seguridad = useSeguridad();
    const permisoPrestamoCajaAhorro = seguridad.tieneAcceso(constantes.opciones.PermisoPrestamoCajaAhorro);
    const permisoCajaAhorro = seguridad.tieneAcceso(constantes.opciones.PermisoCajaAhorro);

    return (
        <>
            <div className="form-row">
                <h5 className="col-12">Datos Bancarios</h5>
                <FieldDropDown required name="banco" label="Banco" colMd={6} source="bancos" filter errors={errors}
                               value={ nomina?.banco } onChange={actualizar}/>
                                        
                <FieldInputNumeroTarjeta  name="numeroTarjeta" label="No. Tarjeta" colMd={6} errors={errors}
                               value={ nomina?.numeroTarjeta }  onChange={actualizar}/>
                                                         
                <FieldTextBox  name="numeroCuenta" label="No. Cuenta" colMd={6} errors={errors} keyFilter={'int'}
                               value={ nomina?.numeroCuenta }  onChange={actualizar}/>
            
                <FieldInputNumeroClabe required name="clabe" label="CLABE" colMd={6} errors={errors}
                               value={ nomina?.clabe }  onChange={actualizar}/> 

                <FieldTextBox required name="beneficiario" label="Beneficiario" colMd={6} errors={errors}
                              value={ nomina?.beneficiario }  onChange={actualizar}/>
                <FieldDropDown required name="relacionBeneficiario" label="Relación" colMd={6} source="relacionesBeneficiario" filter errors={errors}
                               value={ nomina?.relacionBeneficiario } onChange={actualizar}/>
                <FieldInputNumeroPhone required name="telefonoBeneficiario" label="Télefono Beneficiario" colMd={6} errors={errors}
                                  value={ nomina?.telefonoBeneficiario } onChange={actualizar}/>

                <h5 className="col-12">Otra Información Bancaria</h5>
                <FieldDropDown required name="bancoIdSeg" label="Banco" colMd={6} source="bancos" filter errors={errors}
                               value={ nomina?.bancoIdSeg } onChange={actualizar}/>
                                        
                <FieldInputNumeroTarjeta  name="numeroTarjetaSeg" label="No. Tarjeta" colMd={6} errors={errors}
                               value={ nomina?.numeroTarjetaSeg }  onChange={actualizar}/>
                                                         
                <FieldTextBox  name="numeroCuentaSeg" label="No. Cuenta" colMd={6} errors={errors} keyFilter={'int'}
                               value={ nomina?.numeroCuentaSeg }  onChange={actualizar}/>
            
                <FieldInputNumeroClabe required name="clabeSeg" label="CLABE" colMd={6} errors={errors}
                               value={ nomina?.clabeSeg }  onChange={actualizar}/>
                <h5 className="col-12">Información de Sueldo</h5>
                {                    
                (nomina?.SueldoBaseLiquidacion ||
                nomina?.SueldoBaseStaff ||
                nomina?.SueldoBaseOficina ) &&
                (!nomina?.esquemaPagos  || 
                     (nomina?.SueldoBaseLiquidacion && nomina?.esquemaPagos === constantes.esquemaPago.liquidacion) ||
                     (nomina?.SueldoBaseStaff && nomina?.esquemaPagos === constantes.esquemaPago.staff) ||
                     (nomina?.SueldoBaseOficina && nomina?.esquemaPagos === constantes.esquemaPago.oficina)
                )
                &&
                <>
                <FieldDropDown required name="esquemaPagos" label="Esquema de Pagos" colMd={6} source="esquemasPago" filter errors={errors}
                               value={ nomina?.esquemaPagos } onChange={actualizar} valueById/>
                
                <FieldInputNumber required name="sueldoBaseSemanal" label="Sueldo Base Semanal" colMd={6}
                  disabled={(dataTipoEmpleado?.id=== constantes.tipoSueldoInstructores.SueldoTractorInstructorABC  || dataTipoEmpleado?.id=== constantes.tipoSueldoInstructores.SueldoTractorInstructorTBX || dataTipoEmpleado?.id=== constantes.tipoSueldoInstructores.SueldoTractorInstructorREX? false: dataTipoEmpleado?.esOperador )} 
                  value={(dataTipoEmpleado?.id=== constantes.tipoSueldoInstructores.SueldoTractorInstructorABC  || dataTipoEmpleado?.id=== constantes.tipoSueldoInstructores.SueldoTractorInstructorTBX || dataTipoEmpleado?.id=== constantes.tipoSueldoInstructores.SueldoTractorInstructorREX) ? nomina.sueldoBaseSemanal  : !dataTipoEmpleado?.esOperador ? nomina.sueldoBaseSemanal :dataTipoEmpleado.sueldoBaseSemanal}  fractionDigits={2} errors={errors}
                                 onChange={actualizar}/>
                </>
                }
                <FieldInputNumber required name="ispt" label="ISPT" colMd={6} fractionDigits={2} errors={errors}
                   disabled={dataTipoEmpleado?.esOperador} value={ dataTipoEmpleado?.esOperador? dataTipoEmpleado.calculoISPT :nomina?.ispt }  onChange={actualizar}/>
                <FieldCheckBox name="operadorOutsorcing" label="Empleado/Operador Outsourcing" value={nomina?.operadorOutsorcing} 
                    onChange={actualizar} className="py-1" colLg={6} colMd={4} />
                
             

                <FieldInputNumber name="numeroWorkForce" label="Número Outsourcing" colMd={6} fractionDigits={0} errors={errors}
                                  value={ nomina?.numeroWorkForce } onChange={actualizar} useGrouping={false}/>
                <h5 className="col-12">Otra Información</h5>

                <FieldInputNumber name="pensionAlimenticia" label="Pension Alimenticia" colMd={6} fractionDigits={2} errors={errors}
                                  value={ nomina?.pensionAlimenticia } onChange={actualizar}/>
                
                
           
                <FieldDropDown name="proveedorPago" label="Proveedor Pago" colMd={6} source="proveedoresPago" filter errors={errors}
                               value={ nomina?.proveedorPago } onChange={actualizar}/>
               
                <div className={`form-group col-md-6 col-sm-6`}>
                    <label>Pensión Alimenticia</label>
                    <div className="col-auto">
                        <FieldInputNumber name="pensionAlimenticia" colMd={6} fractionDigits={2} errors={errors}
                                          value={ nomina?.pensionAlimenticia } onChange={actualizar}/>
                        <SelectButton value={nomina?.tipoPensionAlimenticia} options={options} onChange={(e) => actualizar(e.value, 'tipoPensionAlimenticia')} />
                    </div>
                </div>

                <FieldCheckBox name="comedorDesactivado" label="Desactivar Comedor" colMd={4} errors={errors}
                               value={ nomina?.comedorDesactivado }  onChange={actualizar}/>

                {(permisoCajaAhorro &&
                 <>
                   <h5 className="col-12">Ahorro</h5>
                    <FieldInputNumber name="ahorro" label="Ahorro" colMd={6} fractionDigits={2} errors={errors}
                                    value={ nomina?.ahorro } onChange={actualizar}/>
                    <FieldCalendar name="fechaInicioAhorro" label="Fecha de inicio de ahorro" error={errors?.fechaInicioAhorro} required colSm={6} disabled={deshabilitar}
                                    value={nomina.fechaInicioAhorro} className="xs" showButtonBar={false} onChange={actualizar}/>    
                    <FieldInputNumber name="saldoahorro" label="Saldo Ahorro" disabled={true} colMd={6} fractionDigits={2} errors={errors}
                                    value={ nomina?.saldoahorro } onChange={actualizar}/>

                    </>
                )}
                {(permisoPrestamoCajaAhorro && modoCajaahorro &&
                      <>
                       <h5 className="col-12">Préstamo Caja Ahorro</h5>
                        <FieldInputNumber name="prestamoCajaAhorro" label="Monto" colMd={6} fractionDigits={2} errors={errors}  disabled={(nomina?.saldoPrestamoahorro) !== 0}
                                        value={ nomina?.prestamoCajaAhorro } onChange={actualizar}/>

                        <FieldInputNumber name="numeroExhibiciones" colMd={6}error={errors?.numeroExhibiciones} value={nomina.numeroExhibiciones} fractionDigits={2} label="No. Exhibiciones" disabled={(nomina?.saldoPrestamoahorro) !== 0}
                                  
                                    onChange={actualizar} />

                        <FieldCheckBox label="Tiene Préstamo" name="jobPrestamos" onChange={actualizar}  colMd={6} value={nomina.jobPrestamos}  disabled={(nomina?.saldoPrestamoahorro) !== 0} />
                        <FieldCalendar name="fechaInicio" label="Fecha de inicio de descuento" colSm={6}  error={errors?.fechaInicio}  disabled={(nomina?.saldoPrestamoahorro) !== 0}
                                    value={nomina.fechaInicio} className="xs" showButtonBar={false} onChange={actualizar} />    
                        <FieldInputNumber name="saldoPrestamoahorro" label="Saldo Préstamo Ahorro" disabled={true} colMd={6} fractionDigits={2} errors={errors}
                                        value={ nomina?.saldoPrestamoahorro } onChange={actualizar}/>

                      </>
                    )}
            </div>
        </>
    )

};

export default Nomina;
