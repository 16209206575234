import useServiceFactory from "../serviceFactory";

const apiUrl = `/reportes/EstatusDiarios`;

const useApiEstatusDiarios = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params }),
        filtrarPost: async (params) => service.post(`${apiUrl}/filtrar`,  params, {showSuccess:false} ),
        sinOperadoresDia: async (params) => service.get(`${apiUrl}/sinOperadoresDia`, { params, showLoading:true   }),
        obtenerSinOperadores: async (params) => service.get(`${apiUrl}/sinOperadores`, { params, showLoading:true   }),
        unidades: async (params) => service.get(`${apiUrl}/unidades`, { params }),
        viajes: async (unidadId, fecha) => service.get(`${apiUrl}/viajes/${unidadId}/${fecha}`),
        actualizar: async (params) => service.post(`${apiUrl}/actualizar`, params),
        obtenerUnidadesDisponibles: async () => service.get(`${apiUrl}/unidades/disponibles`, {}),
        guardarUnidadesDisponibles: async (params) => service.post(`${apiUrl}/unidades/disponibles`, params),
        excel: async (params) =>
            service.get(`${apiUrl}/excel`, {
                params,
                showLoading: true,
                download: true,
                fileName: `EstatusDiarioDetalle.xlsx`,
            }),
    };
};

export default useApiEstatusDiarios;

