import * as yup from "yup";
import constantesOperacion from "../../constantes";
import constantes from "../../constantes";

const viajeVacioId = constantesOperacion.tipoProgramacionViaje.vacio;

export const viajeValidacionEsquema = yup.object().shape({
    cliente: yup.mixed()
        .when(["tipoViajeId"], {
            is: (a) => a !== viajeVacioId,
            then: yup.mixed().required()
        }),
    tipoUnidad: yup.mixed()
        .when(["tipoViajeId"], {
            is: (a) => a !== viajeVacioId,
            then: yup.mixed().required()
        }),
    ruta: yup.mixed()
        .when(["tipoViajeId"], {
            is: (a) => a !== viajeVacioId,
            then: yup.mixed().required()
        }),
    convenio: yup.mixed()
        .when(["tipoViajeId"], {
            is: (a) => a !== viajeVacioId,
            then: yup.mixed().required()
        }),
    idViaje: yup.string().nullable()
        .when("incluirIdViajeExterno", {
            is: true,
            then: yup.string().required()
        }),
    idViajeRetorno: yup.string().nullable()
        .when(["incluirIdViajeExterno", "esViajeRedondo"], {
            is: (a, b) => a && b,
            then: yup.string().required()
        }),
    comision: yup.number()
        .when(["tipoViajeId", "esSegmentado"], {
            is: (a, b) => a !== viajeVacioId && !b,
            then: yup.number().required()
        }),
    rutas: yup.array()
        .of(
            yup.object().shape({
                fecha: yup.date().required(),
                hora: yup.string().required(),
                esCarga: yup.boolean()
                    .when(["esViajeVacio", "esDescarga", "desenganchar"], {
                        is: (a, b, c) => !a && !b && !c,
                        then: yup.boolean().oneOf([true])
                    }),
                desenganchar: yup.boolean()
                    .when(["esViajeVacio", "esCarga", "esDescarga"], {
                        is: (a, b, c) => !a && !b && !c,
                        then: yup.boolean().oneOf([true])
                    }),
                direccion: yup.mixed()
                    .when(["esViajeVacio", "esNuevoTramo"], {
                        is: (a, b) => a || b,
                        then: yup.mixed().required()
                    }),
                fechaInicioSegmento: yup.date().nullable()
                    .when(["mostrarInicioSegmento"], {
                        is: (a) => !!a,
                        then: yup.date().required()
                    }),
                horaInicioSegmento: yup.string().nullable()
                    .when("mostrarInicioSegmento", {
                        is: true,
                        then: yup.string().required()
                    }),
            }, [["esCarga", "desenganchar"]])
        ),
    coordinador: yup.mixed()
        .when("estatus", {
            is: (e) => e > constantes.estatusViajes.porProgramar,
            then: yup.mixed().required()
        }),
});

export const cancelacionViajeEsquema = yup.object().shape({
    motivo: yup.mixed()
        .when(["esTonu", "esFleteEnFalso"], {
            is: (a, b) => !a && !b,
            then: yup.mixed().required()
        }),
    esTonu: yup.boolean()
        .when("esFleteEnFalso", {
            is: false,
            then: yup.boolean().required()
        }),
    distanciaRecorrida: yup.number()
        .when("esFleteEnFalso", {
            is: true,
            then: yup.number().required()
        }),
    tarifa: yup.number()
        .when("esFleteEnFalso", {
            is: true,
            then: yup.number().required()
        }),
    facturacion: yup.number()
        .when("esTonu", {
            is: true,
            then: yup.number().required()
        }),
});

export const agregarAnticipos = yup.object().shape({
    anticipos: yup.array()
        .of(
            yup.object().shape({
                //operador: yup.mixed().required(),
                concepto: yup.mixed().required(),
                monto: yup.number().required(),
                comentario: yup.string().required()
            })
        )
});

export const agregarAnticipo = yup.object().shape({
    //operador: yup.mixed().required(),
    tipoGasto: yup.mixed().required(),
    conceptoCajaChica: yup.mixed().required(),
    monto: yup.number().required(),
    comentario: yup.string().required()
});

export const agregarCompensacion = yup.object().shape({
    operador: yup.mixed().required(),
    tipoBono: yup.mixed().required(),
    compensacion: yup.mixed().required(),
    monto: yup.number().required(),
    comentario: yup.string().required()
});

export const agregarDescuento = yup.object().shape({
    operador: yup.mixed().required(),
    descuento: yup.mixed().required(),
    exhibiciones: yup.number().required(),
    fechaInicio: yup.date().required(),
    monto: yup.number().required(),
    comentario: yup.string().required()
});


export const validacionTimbrarImpo = yup.object().shape({
    articulos: yup.array()
        .of(
            yup.object().shape({
                cantidad: yup.number().required(),
                descripcion:yup.string().required(),
                unidadConClave: yup.mixed().required(),
                peso: yup.number().required(),
                materialPeligroso: yup.string().required(),
                fraccionArancelaria: yup.string().required(),
                pedimento: yup.string().required()
            })
        )
});

export const validacionTimbrarExpo = yup.object().shape({
    articulos: yup.array()
        .of(
            yup.object().shape({
                cantidad: yup.number().required(),
                descripcion:yup.string().required(),
                unidadConClave: yup.mixed().required(),
                peso: yup.number().required(),
                materialPeligroso: yup.string().required(),
                fraccionArancelaria: yup.string().required()
            })
        )
});

export const validaTimbrar = yup.object().shape({
    articulos: yup.array()
        .of(
            yup.object().shape({
                cantidad: yup.number().required(),
                descripcion:yup.string().required(),
                unidadConClave: yup.mixed().required(),
                peso: yup.number().required(),
                materialPeligroso: yup.string().required()
            })
        )
})

export const cancelarCPValidacion = yup.object().shape({
    motivoCancelar: yup.mixed().required()
});

export const cancelarCPOtroValidacion = yup.object().shape({
    motivoCancelar: yup.mixed().required(),
    comentario: yup.string().required()
});
