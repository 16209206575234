import useServiceFactory from "../serviceFactory";

const apiUrl = "/administracion/incidencias";

const useApiIncidencia = () => {
    const service = useServiceFactory({uri: process.env.REACT_APP_API_URL});
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`),
        guardar: async (formData) => service.post(`${apiUrl}`, formData),
        obtenerViaje: async (cartaPorte) => service.get(`${apiUrl}/viaje/${cartaPorte}`),
        agregarCargo: async (id, formData) => service.post(`${apiUrl}/${id}/cargos`, formData),
        quitarCargo: async (id, cargoId) => service.delete(`${apiUrl}/${id}/cargos/${cargoId}`),
        enviarCargo: async (formData) => service.post(`${apiUrl}/enviarCargo`, formData),
        actualizarEstatus: async (id, formData) => service.put(`${apiUrl}/${id}/estatus`, formData),
        obtenerEmpleados : async (params) => service.get(`${"source/empleadosOperativos"}`, {params}),
        excel: async (params) =>
            service.get(`${apiUrl}/excel`, {
                params,
                showLoading: true,
                download: true,
                fileName: `Incidencias.xlsx`,
            }),
        cerrar: async (formData) => service.put(`${apiUrl}/cerrar`, formData)
    };
};

export default useApiIncidencia;
